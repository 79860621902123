import React from "react";
import "../styles/introduction.css";
import bug from "./../images/bug.svg";
import triangle from "./../images/triangle.svg";
import code from "./../images/code.svg";
import { Link } from "react-scroll";
import Resume from "./../images/Nathalie Saab.pdf";
import FloatingShapes from "./FloatingShapes";

const svgImages = [
  bug,
  triangle,
  code,
  triangle,
  code,
  bug,
  code,
  bug,
  triangle,
];

function Introduction() {
  return (
    <>
      <header className="header">
        <div className="header__content ">
          <h1 className="title">Hey</h1>
          <h1 className="title title--secondary purple">I'm Nathalie</h1>
          <p className="header__para">
            I'm a <b className="purple">Software Engineer</b> with a strong
            passion for building applications with great user experiences.
            <br />
            Here's a bit more
            <Link to="about" spy={true} smooth={true} duration={100}>
              <b className="purple about__me---link">about me.</b>
            </Link>
          </p>
          <div className="social__list">
            <a
              href="https://www.linkedin.com/in/nathalie-saab/"
              target="_blank"
              className="social__link click"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              href=" https://github.com/Nathaliesaab "
              target=" _blank "
              className=" social__link click "
            >
              <i className=" fab fa-github "></i>
            </a>
            <a href={Resume} target="_blank " className="social__link click ">
              <i className="fas fa-file-pdf "></i>
            </a>
          </div>
        </div>
      </header>
      <Link
        to="contact"
        className="mail__btn click"
        spy={true}
        smooth={true}
        duration={100}
      >
        <i className="fas fa-envelope "></i>
      </Link>
      <Link
        to="projects"
        className="scroll"
        spy={true}
        smooth={true}
        duration={100}
      >
        <div className="scroll__icon click"></div>
      </Link>
      <FloatingShapes images={svgImages} animationDuration="5s" />
    </>
  );
}

export default Introduction;
