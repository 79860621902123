import React from "react";
import "../styles/skills.css";
import { Icon } from "@iconify/react";

const skillsData = [
  { icon: "devicon:react", text: "React" },
  { icon: "vscode-icons:file-type-angular", text: "Angular" },
  { icon: "devicon:tailwindcss", text: "Tailwind" },
  { icon: "logos:typescript-icon", text: "Typescript" },
  { icon: "logos:html-5", text: "HTML" },
  { icon: "logos:css-3", text: "CSS" },
  { icon: "logos:javascript", text: "JavaScript" },
  { icon: "devicon:github", text: "GitHub" },
  { icon: "carbon:sql", text: "SQL", color: "green" },
  { icon: "devicon:nextjs", text: "Next.js" },
  { icon: "logos:firebase", text: "Firebase" },
  { icon: "skill-icons:redux", text: "Redux" },
  { icon: "tabler:brand-react-native", text: "React Native", color: "#61DAFB" },
  { icon: "logos:java", text: "Java" },
  { icon: "simple-icons:postman", text: "Postman", color: "#ed7547" },
  { icon: "logos:nodejs", text: "Node.js" },
];

function Skills() {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row">
          <h1 className="section__title">
            My <span className="purple"> Skills </span>
          </h1>
          <div className="skills-container" id="skills">
            <div className="wrapper">
              {skillsData.map((skill, index) => (
                <div className={`skill skill-${index}`} key={index}>
                  <Icon
                    className="skill__icon"
                    icon={skill.icon}
                    color={skill.color}
                  />
                  <div className="text">{skill.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
