import React from "react";
import "../styles/footer.css";
import logo from "./../images/logo.png";
import resume from "./../images/Nathalie Saab.pdf";
function Footer() {
  return (
    <footer>
      <div className="container ">
        <div className="row">
          <div className="footer__wrapper ">
            <img src={logo} className=" footer_logo " alt="" />
            <div className=" footer__links ">
              <a
                href=" https://github.com/Nathaliesaab "
                className=" footer__link link__hover-effect link__hover-effect-white "
                target=" _blank"
              >
                Github
              </a>
              <a
                href=" https://www.linkedin.com/in/nathalie-saab/ "
                className=" footer__link link__hover-effect link__hover-effect-white "
                target=" _blank "
              >
                LinkedIn
              </a>
              <a
                href=" "
                className=" footer__link link__hover-effect link__hover-effect-white"
              >
                Contact
              </a>
              <a
                href={resume}
                className="footer__link link__hover-effect link__hover-effect-white "
                target="_blank"
              >
                Resume
              </a>
            </div>
            <div>Copyright © 2022 Nathalie Saab</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
