import React, { useEffect, useRef } from "react";
import "../styles/contactme.css";
import emailjs from "@emailjs/browser";

function ContactMe() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_61pvgmm",
        "template_tgt8knt",
        form.current,
        "user_FS5k7QNkKcXvsvNc3w3Is"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const clearData = () => {
    setTimeout(() => {
      document.getElementById("name").value = "";
      document.getElementById("email").value = "";
      document.getElementById("message").value = "";
    }, 300);
  };

  useEffect = (() => {}, []);

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="row">
          <h1 className="section__title">
            Contact <span className="purple"> Me !</span>
          </h1>
          <div className="contact__wrapper">
            <form
              action=""
              className="contact__form"
              ref={form}
              id="form"
              onSubmit={sendEmail}
            >
              <input
                type="text"
                name="user_name"
                placeholder="Name"
                id="name"
              />
              <input
                type="email"
                id="email"
                placeholder="Email"
                required
                name="user_email"
              />
              <textarea
                name="message"
                id="message"
                rows="10"
                required
                placeholder="Message"
              ></textarea>
              <button
                type="submit"
                className="send"
                value="Send"
                onClick={clearData}
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactMe;
