import React, { Component } from "react";
import "./../styles/introduction.css";
import PropTypes from "prop-types";

class FloatingShapes extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    animationDuration: PropTypes.string,
  };

  static defaultProps = {
    animationDuration: "5s",
  };

  startAnimation = (e) => {
    e.currentTarget.classList.add("floating");
  };

  stopAnimation = (e) => {
    e.currentTarget.classList.remove("floating");
  };

  render() {
    const { images, animationDuration } = this.props;

    return (
      <div className="floating-shapes">
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            className={`shape shape--${index}`}
            alt={`Shape ${index}`}
            onMouseEnter={this.startAnimation}
            onMouseLeave={this.stopAnimation}
            style={{ animationDuration }}
          />
        ))}
      </div>
    );
  }
}

export default FloatingShapes;
