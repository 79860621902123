import { React, useRef } from 'react';
import '../styles/navBar.css'
import logo from './../images/logo.png'
import { Link } from 'react-scroll'
const NavBar = () => {
    let contrastToggle = false;
    function toggleContrast() {
        // console.log(document.body)
        contrastToggle = !contrastToggle;

        contrastToggle ? document.body.classList.add("dark-theme") : document.body.classList.remove("dark-theme");
    }
    return (
        <div className="navBar-container">
            <nav>
                <figure><img id="personal-logo" src={logo} alt="" /></figure>
                <ul className="nav__link--list">
                    <li className="nav__link">
                        <Link to="about" spy={true} smooth={true} duration={100}
                            className="nav__link--anchor link__hover-effect link__hover-effect-black">
                            About
                        </Link>
                    </li>
                    <li className="nav__link">
                        <Link to="projects" className="nav__link--anchor link__hover-effect link__hover-effect-black" spy={true} smooth={true} duration={100}>
                            Projects
                        </Link>
                    </li>
                    <li className="nav__link">
                        <Link className="nav__link--anchor link__hover-effect link__hover-effect-black" to="contact" spy={true} smooth={true} duration={100}>
                            Contact
                        </Link>
                    </li>
                    <li className="nav__link click" onClick={() => toggleContrast()}>
                        <a className="nav__link--anchor link__hover-effect link__hover-effect-black">
                            <i className="fas fa-adjust"></i>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};



export default NavBar;