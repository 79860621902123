import { BrowserRouter as Router } from "react-router-dom";
import { React, useRef } from "react";
import Introduction from "./components/introduction";
import AboutMe from "./components/aboutMe";
import Skills from "./components/skills";
import NavBar from "./components/navbar";
import ContactMe from "./components/contactme";
import Footer from "./components/footer";
import Projects from "./components/projects";

function App() {
  return (
    <div className="App">
      <Router>
        <section
          id="landing-page"
        >
          <NavBar />
          <Introduction />
        </section>
        <AboutMe />
        <Skills />
        <Projects />
        <ContactMe />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
