import React, { useEffect } from "react";
import "../styles/projects.css";
import Library from "./../images/library.png";
import VideoGames from "./../images/VideoGames.png";
import Summarist from "./../images/summarist.jpg";

function Projects() {
  const projects = [
    {
      image: Summarist,
      title: "Summarist",
      desc: "An online library where\
       users can access audio books and enjoy reading or listening to \
       them by subscribing through Stripe.",
      tech: "Nextjs, Redux, Firebase/Firestore and Stripe Payment",
      link: "https://summarist-library.vercel.app/",
    },
    {
      image: VideoGames,
      title: "Video Games App ",
      desc: "Web App integrated with the Rapid API, allowing users to effortlessly browse a vast library of over 350,000 games. \
      Enable users to sort, search, and access detailed information about each game, including ratings, reviews, screenshots, and the platforms they are available on.",
      tech: "Angular, HTML, SCSS, Rapid API",
      link: "https://video-games-app-b20dd.web.app/",
    },
    {
      image: Library,
      title: "Ecommerce Library.",
      desc: "Ecommerce Library that allows users to purchase books\
      online. Users can browse, sort, and add to cart books.",
      tech: "React, HTML, CSS, & JavaScript",
      link: "https://ecommerceapp-react-6.web.app/",
    },
  ];
  return (
    <section id="projects">
      <div className="container">
        <div className="row ">
          <h1 className="section__title">
            Here are some of my <span className=" purple ">projects</span>
          </h1>
          <ul className="project__list ">
            {projects.map((project, index) => (
              <li className=" project" key={index}>
                <a href={project.link} target="blank">
                  <div
                    className={`project__wrapper ${
                      index % 2 === 0 && "project__wrapper--even"
                    }`}
                  >
                    <div className="project__img--wrapper">
                      <img
                        src={project.image}
                        className=" project__img "
                        alt={project.title}
                      />
                      {index !== projects.length - 1 && (
                        <>
                          <div className="project__separator" />
                          <div
                            className={`project__separator__second ${
                              index % 2 === 0
                                ? "second__separator--right"
                                : "second__separator--left"
                            }`}
                          />
                          <div
                            className={`project__separator__third ${
                              index % 2 === 0
                                ? "third__separator--right"
                                : "third__separator--left"
                            }`}
                          />
                        </>
                      )}
                    </div>

                    <div
                      className={`project__description  ${
                        index !== projects.length - 1 &&
                        "project__description--height"
                      }`}
                    >
                      <div className="project__title--wrapper">
                        <h3 className=" project__description--title ">
                          {project.title}
                        </h3>
                        <div className="project__description--links ">
                          <a
                            href={project.link}
                            className=" project__description--link"
                            target="blank"
                          >
                            <i className="fas fa-link" color="white"></i>
                          </a>
                        </div>
                      </div>

                      <h4 className=" project__description--sub-title ">
                        {project.tech}
                      </h4>
                      <p className=" project__description--para ">
                        {project.desc}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Projects;
